import { BrowserModule, Meta } from "@angular/platform-browser";
import { NgModule, LOCALE_ID } from "@angular/core";
import { HttpClientModule } from "@angular/common/http";

import { AppComponent } from "./app.component";
import { AppRoutingModule } from "./app-routing.module";
import { ServicesComponent } from "./pages/services/services.component";
import { HomeComponent } from "./pages/home/home.component";
import { StoreComponent } from "./pages/store/store.component";
import { AboutComponent } from "./pages/about/about.component";
import { ProductsComponent } from "./pages/products/products.component";
import { ProductComponent } from "./pages/product/product.component";
import { CheckoutComponent } from "./pages/checkout/checkout.component";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { LoginComponent } from "./pages/login/login.component";
import { ProfileComponent } from "./pages/profile/profile.component";
import { BrandsComponent } from "./pages/brands/brands.component";
import { OrdersComponent } from "./pages/orders/orders.component";
import { PaymentComponent } from "./pages/payment/payment.component";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { StoresComponent } from "./pages/stores/stores.component";
import { NewsComponent } from "./pages/news/news.component";
import { HumanResourcesComponent } from "./pages/human-resources/human-resources.component";
import { HistoryComponent } from "./pages/history/history.component";
import { ProtocolsComponent } from "./pages/protocols/protocols.component";
import { SustainabilityComponent } from "./pages/sustainability/sustainability.component";
import { TermsConditionsComponent } from "./pages/terms-conditions/terms-conditions.component";
import { SalesConditionComponent } from "./pages/sales-condition/sales-condition.component";
import { PrivacyComponent } from "./pages/privacy/privacy.component";
import { NgMultiSelectDropDownModule } from "ng-multiselect-dropdown";
import { FavoritesComponent } from "./pages/favorites/favorites.component";
import { RegisterComponent } from "./pages/register/register.component";
import { AppointmentComponent } from "./component/appointment/appointment.component";
import { CodeConductComponent } from "./pages/code-conduct/code-conduct.component";
import { NgxLoadingModule } from "ngx-loading";
import { ShareButtonsModule } from "ngx-sharebuttons/buttons";
import { ShareIconsModule } from "ngx-sharebuttons/icons";
import { SearchComponent } from './component/search/search.component';
import { CustomizeComponent } from "./pages/customize/customize.component";
import { ChangePasswordComponent } from "./pages/change-password/change-password.component";
import { ChangeProfileComponent } from "./pages/change-profile/change-profile.component";
import { AddressComponent } from "./pages/address/address.component";
import { LoyaltyComponent } from "./pages/loyalty/loyalty.component";
import { registerLocaleData } from '@angular/common';
import localePt from '@angular/common/locales/pt-PT';
import { FamilyComponent } from "./pages/family/family.component";
import { CampaignsComponent } from "./pages/campaigns/campaigns.component";
import { CampaignComponent } from "./pages/campaign/campaign.component";
import { NewsletterComponent } from './component/newsletter/newsletter.component';
import { NgxGoogleAnalyticsModule, NgxGoogleAnalyticsRouterModule } from "ngx-google-analytics";
import { environment } from "src/environments/environment";
import { SeparateNumberPipe } from './pipes/separate-number.pipe';
import { HealthStylesComponent } from "./pages/health-styles/health-styles.component";
import { HealthStyleComponent } from "./pages/health-style/health-style.component";

registerLocaleData(localePt, 'pt-PT');

@NgModule({
	declarations: [
		AppComponent,
		ServicesComponent,
		CustomizeComponent,
		ChangePasswordComponent,
		ChangeProfileComponent,
		AddressComponent,
		LoyaltyComponent,
		FamilyComponent,
		HomeComponent,
		StoreComponent,
		AboutComponent,
		ProductsComponent,
		ProductComponent,
		CampaignsComponent,
		CampaignComponent,
		HealthStylesComponent,
		HealthStyleComponent,
		CheckoutComponent,
		LoginComponent,
		ProfileComponent,
		BrandsComponent,
		OrdersComponent,
		PaymentComponent,
		StoresComponent,
		HumanResourcesComponent,
		HistoryComponent,
		ProtocolsComponent,
		SustainabilityComponent,
		TermsConditionsComponent,
		SalesConditionComponent,
		PrivacyComponent,
		FavoritesComponent,
		RegisterComponent,
		CodeConductComponent,
		AppointmentComponent,
		SearchComponent,
		NewsletterComponent,
		NewsComponent,
		SeparateNumberPipe
	],
	imports: [
		BrowserModule.withServerTransition({ appId: 'serverApp' }),
		AppRoutingModule,
		HttpClientModule,
		ReactiveFormsModule,
		FormsModule,
		NgbModule,
		NgMultiSelectDropDownModule.forRoot(),
		NgxLoadingModule.forRoot({}),
		ShareButtonsModule,
		ShareIconsModule,
		environment.production ? NgxGoogleAnalyticsModule.forRoot('G-2M6N6JZ63F') : [],
		environment.production ? NgxGoogleAnalyticsRouterModule : []
	],
	providers: [Meta, {
		provide: LOCALE_ID,
		useValue: 'pt-PT'
	}],
	bootstrap: [AppComponent]
})
export class AppModule { }
