import { Component, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { BlogService } from "src/app/services/blog.service";

@Component({
	selector: "app-health-style",
	templateUrl: "./health-style.component.html",
	styleUrls: ["./health-style.component.css"]
})
export class HealthStyleComponent implements OnInit {
	healthStyle: any;

	constructor(private blogService: BlogService, private route: ActivatedRoute) {
		this.route.params.subscribe((params: any) => {
			if (params.id != "undefined") {
				this.load(params.id);
			}
		});
	}

	ngOnInit(): void {
	}

	load(id: string) {
		this.blogService.one(id).subscribe((data: any) => {
			if (data.status === "OK") {
				this.healthStyle = data.data;
			}
		});
	}

	getCategories(healthStyle: any) {
		let names = healthStyle.categories.map((p: any) => p.name);
		return names.join(', ');
	}
}
