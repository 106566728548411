import { Injectable } from '@angular/core';
import { ApiService } from './api.service';

@Injectable({
	providedIn: 'root'
})
export class BlogService {

	constructor(private api: ApiService) { }

	all(categories?: any, exceptions?: any) {
		return this.api.get('blog/all', { id: this.api.MERCHANT_ID, categories, exceptions });
	}
	
	byCategory(categoryId?: any) {
		return this.api.get('blog/by-category', { id: categoryId });
	}

	removeCategory(categoryId?: any) {
		return this.api.get('blog/remove-category', { id: categoryId });
	}

	one(id: string) {
		return this.api.get('blog/one', { id });
	}
}
