import { Component, OnInit } from "@angular/core";
import { BlogService } from "src/app/services/blog.service";

@Component({
	selector: "app-campaigns",
	templateUrl: "./campaigns.component.html",
	styleUrls: ["./campaigns.component.css"]
})
export class CampaignsComponent implements OnInit {
	campaigns: any;
	categoryId: number = 77;

	constructor(private blogService: BlogService) { }

	ngOnInit(): void {
		this.all();
	}

	all() {
		this.blogService.removeCategory(this.categoryId).subscribe((data: any) => {
			if (data.status === "OK") {
				this.campaigns = data.data.posts;
			}
		});
	}

	getCategories(campaign: any) {
		let names = campaign.categories.map((p: any) => p.name);
		return names.join(', ');
	}
}
