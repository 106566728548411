<div class="container">
	<div *ngIf="healthStyle" class="health-style">
		<div class="row">
			<div class="col">
				<div class="health-style-image">
					<img class="img-fluid" src="{{ healthStyle.image }}" />
				</div>
			</div>
		</div>
		<div class="health-style-body px-3 text-light">
			<div class="row my-1">
				<div class="col">
					<small class="text-muted">{{ getCategories(healthStyle) }}</small>
				</div>
				<div class="col-auto">
					<small class="text-muted">{{ healthStyle.date_publish | date }}</small>
				</div>
			</div>
			<h3 class="health-style-title text-light my-5">{{ healthStyle.title }}</h3>
			<div class="text-light" [innerHTML]="healthStyle.content"></div>
		</div>
	</div>
</div>
